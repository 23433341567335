export default {
  props: {
    number: {
      type: String,
      required: true,
    },

    beforeNumber: {
      type: String,
    },

    index: {
      type: Number,
    },

    afterNumber: {
      type: String,
    },

    description: {
      type: String,
    },
  },

  computed: {
    setCurrency() {
      return ['pt-br'].includes(this.$i18n.locale) ? 'R$' : '$'
    },
  },
}
